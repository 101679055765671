<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>登記預約中心</b-breadcrumb-item>
            <b-breadcrumb-item active>預約管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="bookingPresets"
                :fields="fields"
              >
                <template #cell(name)="data">
                  <RouterLink
                    :to="{ name: 'BookingList', params: { id: data.item.id } }"
                  >
                    {{ data.item.name }}
                  </RouterLink>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_LIST])"
                    variant="inverse-dark"
                    @click="showFormUrl(data.item)"
                  >
                    <i class="fa fa-link"></i>
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_LIST])"
                    variant="inverse-warning"
                    :to="{ name: 'BookingList', params: { id: data.item.id } }"
                  >
                    <span class="mdi mdi-format-list-bulleted"></span>
                    預約紀錄
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.BOOKING_SETTING])"
                    variant="inverse-info"
                    :to="{
                      name: 'BookingBranchPresetEdit',
                      params: {
                        id: data.item.id,
                        branch: branchId
                      }
                    }"
                  >
                    <i class="fa fa-cog"></i>
                      預約設定
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>

    <FormUrlModal ref="formUrlModal" />
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import bookingApi from "@/apis/booking";
import { mapState, mapGetters } from "vuex";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import FormUrlModal from "@/components/Dashboard/Booking/FormUrlModal.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: {
    CustomPagination,
    FormUrlModal,
  },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      showLoading: false,
      fieldsInit: false,
      branchId: null,
      fields: [
        {
          key: "name",
          label: "名稱",
          sortable: true,
        },
        // {
        //   key: "bookings_count",
        //   label: "預約筆數",
        //   sortable: true,
        // },
        {
          key: "actions",
          label: "管理",
        },
      ],
      bookingPresets: [],
      totalRows: 0,
      query: {
        page: 1,
        per_page: 20,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("general", ["currentBranch"]),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchBookingPresets();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    if (this.currentBranch) {
      this.branchId = this.currentBranch.id
    }
    if (this.$route.query.branch_id) {
      this.branchId = this.$route.query.branch_id
    }
  },
  async mounted() {
    await this.fetchBookingPresets();
    this.initialized = true;
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchBookingPresets() {
      try {
        this.showLoading = true;
        const params = {
          ...this.query,
          status: 'published'
        };
        const response = await bookingApi.getBookingPresets(params);
        this.bookingPresets = response.data.data;
        this.totalRows = response.data.meta.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    showFormUrl(bookingPreset) {
      this.$refs.formUrlModal.showFormUrl(bookingPreset);
    },
  },
};
</script>
